<template>
  <div class="DepotsManagement">
    <el-container>
      <el-header>
        <h1>DepotsID管理</h1>
      </el-header>
      <el-main>
        <el-input
          v-model="searchQuery"
          placeholder="搜索游戏ID或名称"
          @input="fetchDepotsAndKeys"
          clearable
          prefix-icon="el-icon-search"
          style="margin-bottom: 20px;"
        ></el-input>
        <el-button type="primary" @click="openAddDialog">新增</el-button>
        <el-button type="primary" @click="openBulkAddDialog">批量新增</el-button>
        <el-table :data="combinedData" v-if="combinedData.length > 0" style="width: 100%;">
          <el-table-column prop="gameid" label="游戏ID" width="100"></el-table-column>
          <el-table-column prop="gamename" label="游戏名称" width="150"></el-table-column>
          <el-table-column prop="depotsid" label="清单ID" width="150"></el-table-column>
          <el-table-column prop="keysr" label="KEYS" width="250"></el-table-column>
          <el-table-column prop="manifasetid" label="ManifasetID" width="150"></el-table-column>
          <el-table-column prop="manifaset" label="清单" width="250"></el-table-column>
          <el-table-column prop="storage" label="占用空间(B)" width="150"></el-table-column>
          <el-table-column label="操作" width="200">
            <template v-slot="scope">
              <el-button @click="openEditDialog(scope.row)" type="warning" size="small">修改</el-button>
              <el-button @click="deleteDepot(scope.row)" type="danger" size="small">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div v-else>
          <el-empty description="没有数据可显示"></el-empty>
        </div>
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          :page-size="pageSize"
          :current-page="currentPage"
          @current-change="handleCurrentChange"
          style="margin-top: 20px; text-align: center;"
        ></el-pagination>
      </el-main>
    </el-container>

    <el-dialog title="编辑数据" v-model="dialogVisible" width="30%" @close="resetForm">
      <el-form :model="currentDepot" label-width="100px">
        <el-form-item label="游戏ID">
          <el-input v-model="currentDepot.gameid"></el-input>
        </el-form-item>
        <el-form-item label="清单ID">
          <el-input v-model="currentDepot.depotsid"></el-input>
        </el-form-item>
        <el-form-item label="KEYS">
          <el-input v-model="currentDepot.keysr"></el-input>
        </el-form-item>
        <el-form-item label="ManifasetID">
          <el-input v-model="currentDepot.manifasetid"></el-input>
        </el-form-item>
        <el-form-item label="清单">
          <el-input v-model="currentDepot.manifaset"></el-input>
        </el-form-item>
        <el-form-item label="占用空间(B)">
          <el-input v-model="currentDepot.storage"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="saveDepot">保存</el-button>
      </template>
    </el-dialog>

    <el-dialog title="新增数据" v-model="addDialogVisible" width="30%" @close="resetForm">
      <el-form :model="currentDepot" label-width="100px">
        <el-form-item label="游戏ID">
          <el-input v-model="currentDepot.gameid"></el-input>
        </el-form-item>
        <el-form-item label="清单ID">
          <el-input v-model="currentDepot.depotsid"></el-input>
        </el-form-item>
        <el-form-item label="KEYS">
          <el-input v-model="currentDepot.keysr"></el-input>
        </el-form-item>
        <el-form-item label="ManifasetID">
          <el-input v-model="currentDepot.manifasetid"></el-input>
        </el-form-item>
        <el-form-item label="清单">
          <el-input v-model="currentDepot.manifaset"></el-input>
        </el-form-item>
        <el-form-item label="占用空间(B)">
          <el-input v-model="currentDepot.storage"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="addDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="addDepot">保存</el-button>
      </template>
    </el-dialog>

    <el-dialog title="批量新增数据" v-model="bulkAddDialogVisible" width="50%" @close="resetBulkAddForm">
      <el-input
        type="textarea"
        v-model="bulkAddText"
        placeholder="输入数据，每行一条记录，字段顺序：游戏ID,清单ID,KEYS,ManifasetID,清单,占用空间"
        rows="10"
      ></el-input>
      <template #footer>
        <el-button @click="bulkAddDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="bulkAddDepots">批量新增</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import axios from '../axios';
import { ElMessage } from 'element-plus';

export default {
  name: 'DepotsManagement',
  data() {
    return {
      combinedData: [],
      searchQuery: '',
      dialogVisible: false,
      addDialogVisible: false,
      bulkAddDialogVisible: false,
      currentDepot: {
        gameid: '',
        depotsid: '',
        keysr: '',
        manifasetid: '',
        manifaset: '',
        storage: ''
      },
      bulkAddText: '',
      uploadHeaders: {
        'Authorization': `Bearer ${localStorage.getItem('user-token')}`
      },
      currentPage: 1,
      pageSize: 10,
      total: 0
    };
  },
  created() {
    this.fetchDepotsAndKeys();
  },
  methods: {
    async fetchDepotsAndKeys() {
      try {
        const token = localStorage.getItem('user-token');
        const response = await axios.post('/index.php?action=getDepotsAndKeys', {
          token,
          search: this.searchQuery,
          page: this.currentPage,
          size: this.pageSize
        });
        if (response.data.code === 200) {
          this.combinedData = response.data.keys || [];
          this.total = parseInt(response.data.total, 10) || 0;
        } else {
          this.combinedData = [];
          this.total = 0;
          ElMessage.error(response.data.message || '获取数据失败');
        }
      } catch (error) {
        console.error('Error fetching depots and keys:', error);
        ElMessage.error('获取数据失败，请稍后重试');
      }
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.fetchDepotsAndKeys();
    },
    openEditDialog(depot) {
      this.currentDepot = { ...depot };
      this.dialogVisible = true;
    },
    async saveDepot() {
      try {
        const token = localStorage.getItem('user-token');
        const response = await axios.post('/index.php?action=updateDepotOrKey', {
          token,
          depot: JSON.stringify(this.currentDepot)
        });
        if (response.data.success) {
          ElMessage.success('数据已更新');
          this.dialogVisible = false;
          this.fetchDepotsAndKeys();
        } else {
          ElMessage.error(response.data.message);
        }
      } catch (error) {
        console.error('Error updating depot:', error);
        ElMessage.error('更新失败，请稍后重试');
      }
    },
    async deleteDepot(depot) {
      try {
        const token = localStorage.getItem('user-token');
        const response = await axios.post('/index.php?action=deleteDepotOrKey', {
          token,
          depot: JSON.stringify(depot)
        });
        if (response.data.success) {
          ElMessage.success('数据已删除');
          this.fetchDepotsAndKeys();
        } else {
          ElMessage.error(response.data.message);
        }
      } catch (error) {
        console.error('Error deleting depot:', error);
        ElMessage.error('删除失败，请稍后重试');
      }
    },
    openAddDialog() {
      this.resetForm();
      this.addDialogVisible = true;
    },
    async addDepot() {
      try {
        const token = localStorage.getItem('user-token');
        const response = await axios.post('/index.php?action=addDepotOrKey', {
          token,
          depot: JSON.stringify(this.currentDepot)
        });
        if (response.data.success) {
          ElMessage.success('数据已新增');
          this.addDialogVisible = false;
          this.fetchDepotsAndKeys();
        } else {
          ElMessage.error(response.data.message);
        }
      } catch (error) {
        console.error('Error adding depot:', error);
        ElMessage.error('新增失败，请稍后重试');
      }
    },
    openBulkAddDialog() {
      this.resetBulkAddForm();
      this.bulkAddDialogVisible = true;
    },
    async bulkAddDepots() {
      try {
        const token = localStorage.getItem('user-token');
        const response = await axios.post('/index.php?action=bulkAddDepots', {
          token,
          bulkData: this.bulkAddText
        });
        if (response.data.success) {
          ElMessage.success('批量新增成功');
          this.bulkAddDialogVisible = false;
          this.fetchDepotsAndKeys();
        } else {
          ElMessage.error(response.data.message);
        }
      } catch (error) {
        console.error('Error bulk adding depots:', error);
        ElMessage.error('批量新增失败，请稍后重试');
      }
    },
    resetForm() {
      this.currentDepot = {
        gameid: '',
        depotsid: '',
        keysr: '',
        manifasetid: '',
        manifaset: '',
        storage: ''
      };
    },
    resetBulkAddForm() {
      this.bulkAddText = '';
    }
  }
};
</script>

<style scoped>
.DepotsManagement {
  padding: 20px;
}
</style>
