<template>
  <div class="FileManagement">
    <el-container>
      <el-header>
        <h1>文件管理</h1>
      </el-header>
      <el-main>
        <el-tabs v-model="activeTab">
          <el-tab-pane label="Manifaset 文件" name="manifaset">
            <div style="margin-bottom: 20px;">
              <!-- <el-button type="primary" @click="openUploadDialog">上传文件</el-button> -->
              <el-button type="primary" @click="openNewFolderDialog">新建文件夹或文件</el-button>
            </div>
            <div class="file-container">
              <div v-for="file in files" :key="file.path" class="file-item">
                <el-image
                  :src="file.isDirectory ? folderIcon : fileIcon"
                  class="file-icon"
                  @click="handleNodeClick(file)"
                ></el-image>
                <span class="file-name" :title="file.name">{{ file.name }}</span>
                <div class="file-actions">
                  <div class="file-size">{{ formatSize(file.size) }}</div>
                  <el-dropdown @command="handleCommand">
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item command="rename">重命名</el-dropdown-item>
                        <el-dropdown-item command="delete">删除</el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                    <span class="el-dropdown-link">
                      <el-button type="text" icon="el-icon-more" @click.stop="openMoreMenu(file)"></el-button>
                    </span>
                  </el-dropdown>
                </div>
              </div>
            </div>
            <el-pagination
              background
              layout="prev, pager, next"
              :total="totalFiles"
              :page-size="pageSize"
              v-model:currentPage="currentPage"
              @current-change="fetchFiles"
              style="margin-top: 20px; text-align: center;"
            ></el-pagination>
          </el-tab-pane>
          <el-tab-pane label="Games 文件" name="games">
            <div style="margin-bottom: 20px;">
              <!-- <el-button type="primary" @click="openUploadDialog">上传文件</el-button> -->
              <el-button type="primary" @click="openNewFolderDialog">新建文件夹或文件</el-button>
            </div>
            <div class="file-container">
              <div v-for="file in files" :key="file.path" class="file-item">
                <el-image
                  :src="file.isDirectory ? folderIcon : fileIcon"
                  class="file-icon"
                  @click="handleNodeClick(file)"
                ></el-image>
                <span class="file-name" :title="file.name">{{ file.name }}</span>
                <div class="file-actions">
                  <div class="file-size">{{ formatSize(file.size) }}</div>
                  <el-dropdown @command="handleCommand">
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item command="rename">重命名</el-dropdown-item>
                        <el-dropdown-item command="delete">删除</el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                    <span class="el-dropdown-link">
                      <el-button type="text" icon="el-icon-more" @click.stop="openMoreMenu(file)"></el-button>
                    </span>
                  </el-dropdown>
                </div>
              </div>
            </div>
            <el-pagination
              background
              layout="prev, pager, next"
              :total="totalFiles"
              :page-size="pageSize"
              v-model:currentPage="currentPage"
              @current-change="fetchFiles"
              style="margin-top: 20px; text-align: center;"
            ></el-pagination>
          </el-tab-pane>
        </el-tabs>

        <!-- <el-dialog title="上传文件" v-model="uploadDialogVisible" width="30%">
          <el-upload
            :http-request="uploadFile"
            :data="{ path: currentPath }"
            :on-success="handleUploadSuccess"
            :limit="1"
            :on-exceed="handleUploadExceed"
            :before-upload="beforeUpload"
            directory
          >
            <template #trigger>
              <el-button type="primary">选取文件</el-button>
            </template>
            <template #tip>
              <div class="el-upload__tip">文件大小不能超过 100MB</div>
            </template>
          </el-upload>
          <template #footer>
            <el-button @click="uploadDialogVisible = false">取消</el-button>
          </template>
        </el-dialog> -->

        <el-dialog title="新建文件夹或文件" v-model="newFolderDialogVisible" width="30%">
          <el-input v-model="newFolderName" placeholder="请输入文件夹或文件名称"></el-input>
          <template #footer>
            <el-button @click="newFolderDialogVisible = false">取消</el-button>
            <el-button type="primary" @click="createFolderOrFile">确定</el-button>
          </template>
        </el-dialog>

        <el-dialog title="重命名文件" v-model="renameDialogVisible" width="30%">
          <el-input v-model="renameFileName" placeholder="请输入新的文件名"></el-input>
          <template #footer>
            <el-button @click="renameDialogVisible = false">取消</el-button>
            <el-button type="primary" @click="renameConfirmed">确定</el-button>
          </template>
        </el-dialog>

        <el-dialog title="编辑文件内容" v-model="editDialogVisible" width="50%">
          <el-input type="textarea" v-model="fileContent" rows="20" placeholder="修改文件内容"></el-input>
          <template #footer>
            <el-button @click="editDialogVisible = false">取消</el-button>
            <el-button type="primary" @click="saveFileContent">保存</el-button>
          </template>
        </el-dialog>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import axios from '../axios';
import { ElMessage } from 'element-plus';

export default {
  name: 'FileManagement',
  data() {
    return {
      activeTab: 'manifaset',
      files: [],
      selectedFiles: [],
      // uploadDialogVisible: false,
      newFolderDialogVisible: false,
      renameDialogVisible: false,
      editDialogVisible: false,
      newFolderName: '',
      renameFileName: '',
      fileContent: '',
      currentFile: null,
      currentPath: '../download/manifaset',
      currentPage: 1,
      pageSize: 5,
      totalFiles: 0,
      defaultProps: {
        children: 'children',
        label: 'name',
      },
      folderIcon: 'https://creazilla-store.fra1.digitaloceanspaces.com/icons/3259639/folder-open-icon-md.png',
      fileIcon: 'https://ts1.cn.mm.bing.net/th/id/R-C.5d509b38b2a25b895f6a1e5cf588fb8a?rik=nZXNsWJoEW86aw&riu=http%3a%2f%2ficons.iconarchive.com%2ficons%2fpaomedia%2fsmall-n-flat%2f1024%2ffile-text-icon.png&ehk=IOj0Cc9B4pM6Upr4nH9bI6gh%2bwTra7o33drOvyCJbc0%3d&risl=&pid=ImgRaw&r=0',
      moreMenuFile: null,
      uploadUrl: '/index.php?action=uploadFile',
    };
  },
  created() {
    this.fetchFiles();
  },
  watch: {
    activeTab() {
      this.currentPath = this.activeTab === 'manifaset' ? '../download/manifaset' : '../download/games';
      this.fetchFiles();
    },
  },
  methods: {
    async fetchFiles() {
      try {
        const token = localStorage.getItem('user-token');
        const response = await axios.post('/index.php?action=listFiles', {
          token,
          path: this.currentPath,
          page: this.currentPage,
          size: this.pageSize,
        });
        this.files = response.data.files || [];
        this.totalFiles = parseInt(response.data.total, 10) || 0;
      } catch (error) {
        console.error('Error fetching files:', error);
        ElMessage.error('获取文件失败，请稍后重试');
      }
    },
    handleNodeClick(data) {
      if (data.isDirectory) {
        this.currentPath = data.path;
        this.fetchFiles();
      } else if (this.activeTab !== 'manifaset') {
        this.openEditDialog(data);
      }
    },
//     openUploadDialog() {
//   this.uploadDialogVisible = true;
// },



// async uploadFile({ file, onSuccess, onError }) {
//   const token = localStorage.getItem('user-token');
//   const formData = new FormData();
//   formData.append('file', file);
//   formData.append('path', this.currentPath);
//   formData.append('token', token);

//   try {
//     const response = await axios.post(this.uploadUrl, formData, {
//       headers: {
//         'Content-Type': 'multipart/form-data',
//       },
//     });
//     if (response.data.success) {
//       onSuccess(response.data);
//       ElMessage.success('文件上传成功');
//       this.uploadDialogVisible = false;
//       this.fetchFiles();
//     } else {
//       onError(new Error(response.data.message));
//       ElMessage.error(response.data.message);
//     }
//   } catch (error) {
//     onError(error);
//     ElMessage.error('上传失败，请稍后重试');
//   }
// },



handleUploadExceed() {
  ElMessage.warning('一次只能上传一个文件');
},
// beforeUpload(file) {
//   const isLt100M = file.size / 1024 / 1024 < 100;
//   if (!isLt100M) {
//     ElMessage.error('文件大小不能超过 100MB');
//   }
//   return isLt100M;
// },
openNewFolderDialog() {
  this.newFolderDialogVisible = true;
},
async createFolderOrFile() {
  try {
    const token = localStorage.getItem('user-token');
    const isFile = this.newFolderName.endsWith('.txt');
    const action = isFile ? 'createFile' : 'createFolder';
    const response = await axios.post(`/index.php?action=${action}`, {
      token,
      path: this.currentPath,
      [isFile ? 'fileName' : 'folderName']: this.newFolderName,
    });
    if (response.data.success) {
      ElMessage.success(`${isFile ? '文件' : '文件夹'}创建成功`);
      this.newFolderDialogVisible = false;
      this.fetchFiles();
    } else {
      ElMessage.error(response.data.message);
    }
  } catch (error) {
    console.error('Error creating folder or file:', error);
    ElMessage.error('创建文件夹或文件失败，请稍后重试');
  }
},
renameFile(file) {
  this.currentFile = file;
  this.renameFileName = file.name;
  this.renameDialogVisible = true;
},
async renameConfirmed() {
  try {
    const token = localStorage.getItem('user-token');
    const response = await axios.post('/index.php?action=renameFile', {
      token,
      path: this.currentPath,
      oldName: this.currentFile.name,
      newName: this.renameFileName,
    });
    if (response.data.success) {
      ElMessage.success('文件重命名成功');
      this.renameDialogVisible = false;
      this.fetchFiles();
    } else {
      ElMessage.error(response.data.message);
    }
  } catch (error) {
    console.error('Error renaming file:', error);
    ElMessage.error('重命名失败，请稍后重试');
  }
},
async deleteFile(file) {
  try {
    const token = localStorage.getItem('user-token');
    const response = await axios.post('/index.php?action=deleteFile', {
      token,
      path: this.currentPath,
      fileName: file.name,
    });
    if (response.data.success) {
      ElMessage.success('文件删除成功');
      this.fetchFiles();
    } else {
      ElMessage.error(response.data.message);
    }
  } catch (error) {
    console.error('Error deleting file:', error);
    ElMessage.error('删除失败，请稍后重试');
  }
},
handleSelectionChange(selection) {
  this.selectedFiles = selection;
},
async deleteSelectedFiles() {
  try {
    const token = localStorage.getItem('user-token');
    const fileNames = this.selectedFiles.map((file) => file.name);
    const response = await axios.post('/index.php?action=deleteFiles', {
      token,
      path: this.currentPath,
      fileNames,
    });
    if (response.data.success) {
      ElMessage.success('文件删除成功');
      this.fetchFiles();
    } else {
      ElMessage.error(response.data.message);
    }
  } catch (error) {
    console.error('Error deleting selected files:', error);
    ElMessage.error('删除失败，请稍后重试');
  }
},
async openEditDialog(file) {
  this.currentFile = file;
  try {
    const token = localStorage.getItem('user-token');
    const response = await axios.post('/index.php?action=getFileContent', {
      token,
      path: file.path,
    });
    this.fileContent = response.data.content || '';
    this.editDialogVisible = true;
  } catch (error) {
    console.error('Error fetching file content:', error);
    ElMessage.error('获取文件内容失败，请稍后重试');
  }
},
async saveFileContent() {
  try {
    const token = localStorage.getItem('user-token');
    const response = await axios.post('/index.php?action=saveFileContent', {
      token,
      path: this.currentFile.path,
      content: this.fileContent,
    });
    if (response.data.success) {
      ElMessage.success('文件内容已保存');
      this.editDialogVisible = false;
      this.fetchFiles();
    } else {
      ElMessage.error(response.data.message);
    }
  } catch (error) {
    console.error('Error saving file content:', error);
    ElMessage.error('保存文件内容失败，请稍后重试');
  }
},
formatSize(size) {
  if (size < 1024) return size + ' B';
  if (size < 1024 * 1024) return (size / 1024).toFixed(2) + ' KB';
  return (size / 1024 / 1024).toFixed(2) + ' MB';
},
openMoreMenu(file) {
  this.moreMenuFile = file;
},
handleCommand(command) {
  if (this.moreMenuFile) {
    if (command === 'rename') {
      this.renameFile(this.moreMenuFile);
    } else if (command === 'delete') {
      this.deleteFile(this.moreMenuFile);
    }
    this.moreMenuFile = null;
  }
},
},
};
</script>

<style scoped>
.FileManagement {
  padding: 20px;
}
.file-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.file-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
  cursor: pointer;
}
.file-icon {
  width: 50px;
  height: 50px;
}
.file-size {
  font-size: 12px;
  color: gray;
}
.file-name {
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.file-actions {
  display: flex;
  align-items: center;
}

/* 每10个文件换行 */
.file-container .file-item:nth-child(10n+1) {
  clear: left;
}
</style>
