<template>
  <div class="ManageDistributors">
    <el-container style="margin-top: 3rem;">
      <el-header style="display: flex; justify-content: space-between;">
        <h1>经销商列表</h1>
        <el-button
          type="primary"
          @click="exportToExcel"
          style="float: right; background-color: #409EFF; color: white;"
        >
          导出 Excel
        </el-button>
      </el-header>
      <el-main>
        <el-input
          v-model="searchQuery"
          placeholder="搜索用户名"
          @input="fetchDistributors"
          clearable
          prefix-icon="el-icon-search"
          style="margin-bottom: 20px;"
        ></el-input>
        <el-table :data="paginatedDistributors" v-if="distributors.length > 0" style="width: 100%;">
          <el-table-column prop="username" label="用户名" width="150"></el-table-column>
          <el-table-column prop="owner" label="所属上级" width="150"></el-table-column>
          <el-table-column prop="balance" label="可用制卡次数" width="150"></el-table-column>
          <el-table-column prop="balanceZS" label="可用赠卡次数" width="150"></el-table-column>
          <el-table-column prop="createNum" label="累计创卡次数" width="150"></el-table-column>
          <el-table-column prop="createNumZS" label="累计赠卡次数" width="150"></el-table-column>
          <el-table-column prop="type2_count" label="卡密累计使用次数" width="150"></el-table-column>
          <el-table-column prop="type1_count" label="累计封禁卡密次数" width="150"></el-table-column>
          <el-table-column prop="promissName" label="权限" width="150">
            <template #default="scope">
              <el-tag :type="scope.row.promissID === '0' ? 'info' : 'success'">
                {{ scope.row.promissName }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="bindQQ" label="备注项" width="150"></el-table-column>
          <el-table-column prop="bindPhone" label="绑定手机" width="150"></el-table-column>
          <el-table-column prop="TaobaoShop" label="关联淘宝店" width="150"></el-table-column>
          <el-table-column prop="loginTime" label="上次登陆时间" width="200" sortable></el-table-column>
          <el-table-column prop="createTime" label="注册时间" width="200" sortable></el-table-column>
          <el-table-column prop="loginIP" label="登录IP" width="150"></el-table-column>
          <el-table-column label="操作" width="200">
            <template #default="scope">
              <el-button
                @click="toggleBan(scope.row)"
                :type="scope.row.types === '1' ? 'danger' : 'primary'"
                size="small"
              >
                {{ scope.row.types === "1" ? '解封' : '封禁' }}
              </el-button>
              <el-button @click="openEditDialog(scope.row)" type="warning" size="small">
                编辑
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div v-else>
          <el-empty description="没有经销商可显示"></el-empty>
        </div>
        <el-pagination
          background
          layout="prev, pager, next"
          :total="totalPages * pageSize"
          :page-size="pageSize"
          v-model:currentPage="currentPage"
          @current-change="fetchDistributors"
          style="margin-top: 20px; text-align: center;"
        ></el-pagination>
      </el-main>
    </el-container>

    <el-dialog
      title="编辑经销商"
      v-model="dialogVisible"
      width="30%"
      @close="resetForm"
    >
      <el-form :model="currentDistributor" label-width="100px">
        <el-form-item label="用户名">
          <el-input v-model="currentDistributor.username" disabled></el-input>
        </el-form-item>
        <el-form-item label="密码">
          <el-input type="password" v-model="currentDistributor.password"></el-input>
        </el-form-item>
        <el-form-item label="绑定手机">
          <el-input v-model="currentDistributor.bindPhone"></el-input>
        </el-form-item>
        <el-form-item label="所属上级">
          <el-input v-model="currentDistributor.owner"></el-input>
        </el-form-item>
        <el-form-item label="可用制卡次数">
          <el-input v-model="currentDistributor.balance"></el-input>
        </el-form-item>
        <el-form-item label="可用赠卡次数">
          <el-input v-model="currentDistributor.balanceZS"></el-input>
        </el-form-item>
        <el-form-item label="权限">
          <el-select v-model="currentDistributor.promissID" placeholder="请选择权限">
            <el-option v-for="permission in permissions" :key="permission.promissID" :label="permission.name" :value="permission.promissID"></el-option>
            <el-option label="取消额外权限" value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注项">
          <el-input v-model="currentDistributor.bindQQ"></el-input>
        </el-form-item>
        <el-form-item label="关联淘宝店">
          <el-input v-model="currentDistributor.TaobaoShop"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="saveDistributor">保存</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import axios from '../axios';
import { ElMessage } from 'element-plus';
import * as XLSX from 'xlsx'; // 导入xlsx库

export default {
  name: 'ManageDistributors',
  data() {
    return {
      distributors: [],
      permissions: [], // 用于存储权限列表
      searchQuery: '',
      currentPage: 1,
      pageSize: 10,
      dialogVisible: false,
      currentDistributor: {
        username: '',
        password: '',
        bindPhone: '',
        owner: '',
        balance: '',
        createNum: '',
        balanceZS: '',
        createNumZS: '',
        promissID: '', // 修改为 promissID
        bindQQ: '',
        TaobaoShop: '',
        loginTime: '',
        createTime: '',
        loginIP: '',
        type2_count: '',
        type1_count: ''
      }
    };
  },
  computed: {
    filteredDistributors() {
      return this.distributors.filter(distributor =>
        distributor.username.includes(this.searchQuery)
      );
    },
    paginatedDistributors() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      return this.filteredDistributors.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredDistributors.length / this.pageSize);
    }
  },
  created() {
    this.fetchDistributors();
  },
  methods: {
    adjustDialogWidth() {
      // 如果屏幕宽度小于768px，则设置模态框宽度为100%
      this.dialogWidth = window.innerWidth < 768 ? '100%' : '30%';
    },
    async fetchDistributors() {
      try {
        const token = localStorage.getItem('user-token');
        const response = await axios.post('/index.php?action=getDistributors', { token, search: this.searchQuery });
        this.distributors = response.data.distributors.map(distributor => {
          return {
            ...distributor,
            asyas: distributor.type2_count - distributor.type1_count // 计算asyas值并赋值
          };
        });
      } catch (error) {
        console.error('Error fetching distributors:', error);
        this.distributors = [];
      }
    },
    async fetchPermissions() {
      try {
        const token = localStorage.getItem('user-token');
        const response = await axios.post('/index.php?action=getPromissGroups', { token });
        this.permissions = response.data.groups || [];
      } catch (error) {
        console.error('Error fetching permissions:', error);
        this.permissions = [];
      }
    },
    async toggleBan(distributor) {
      try {
        const token = localStorage.getItem('user-token');
        const response = await axios.post('/index.php?action=toggleDistributorBan', { token, username: distributor.username });
        if (response.data.success) {
          ElMessage.success('状态已更新');
          await this.fetchDistributors();
        } else {
          ElMessage.error(response.data.message);
        }
      } catch (error) {
        console.error('Error toggling ban:', error);
        ElMessage.error('操作失败，请稍后重试');
      }
    },
    async openEditDialog(distributor) {
      this.currentDistributor = { ...distributor, password: '' };
      this.dialogVisible = true;
      await this.fetchPermissions(); // 在打开编辑对话框时获取权限列表
    },
    async saveDistributor() {
      try {
        const token = localStorage.getItem('user-token');
        const { username, password, bindPhone, owner, balance, balanceZS, promissID, bindQQ, TaobaoShop } = this.currentDistributor;
        const response = await axios.post('/index.php?action=updateDistributor', { token, username, password, bindPhone, owner, balance, balanceZS, promissID, bindQQ, TaobaoShop });
        if (response.data.success) {
          ElMessage.success('经销商信息已更新');
          this.dialogVisible = false;
          this.fetchDistributors();
        } else {
          ElMessage.error(response.data.message);
        }
      } catch (error) {
        console.error('Error updating distributor:', error);
        ElMessage.error('更新失败，请稍后重试');
      }
    },
    resetForm() {
      this.currentDistributor = {
        username: '',
        password: '',
        bindPhone: '',
        owner: '',
        balance: '',
        createNum: '',
        balanceZS: '',
        createNumZS: '',
        promissID: '', // 修改为 promissID
        bindQQ: '',
        TaobaoShop: '',
        loginTime: '',
        createTime: '',
        loginIP: '',
        type2_count: '',
        type1_count: ''
      };
    },
    // 获取当前日期时间字符串，用于文件命名
    getCurrentDateTime() {
      const now = new Date();
      const year = now.getFullYear();
      const month = (now.getMonth() + 1).toString().padStart(2, '0');
      const day = now.getDate().toString().padStart(2, '0');
      const hours = now.getHours().toString().padStart(2, '0');
      const minutes = now.getMinutes().toString().padStart(2, '0');
      const seconds = now.getSeconds().toString().padStart(2, '0');
      return `${year}${month}${day}${hours}${minutes}${seconds}`;
    },
    // 导出 Excel 文件，字段名为中文，文件名为当前时间
    exportToExcel() {
      // 映射数据，转换为中文列名
      const translatedData = this.distributors.map(distributor => ({
        '用户名': distributor.username,
        '所属上级': distributor.owner,
        '可用制卡次数': distributor.balance,
        '累计创卡次数': distributor.createNum,
        '可用赠卡次数': distributor.balanceZS,
        '累计赠卡次数': distributor.createNumZS,
        '卡密累计使用次数': distributor.type2_count,
        '累计封禁卡密次数': distributor.type1_count,
        '实际用卡': distributor.asyas,
        '权限': distributor.promissName,
        '备注项': distributor.bindQQ,
        '绑定手机': distributor.bindPhone,
        '关联淘宝店': distributor.TaobaoShop,
        '上次登陆时间': distributor.loginTime,
        '注册时间': distributor.createTime,
        '登录IP': distributor.loginIP
      }));

      const ws = XLSX.utils.json_to_sheet(translatedData); // 将处理后的数据转换为工作表
      const wb = XLSX.utils.book_new(); // 创建新的工作簿
      XLSX.utils.book_append_sheet(wb, ws, 'Distributors'); // 将工作表添加到工作簿中

      // 使用当前时间命名文件
      const fileName = `${this.getCurrentDateTime()}_经销商数据导出.xlsx`;

      XLSX.writeFile(wb, fileName); // 导出 Excel 文件
    }
  },
  watch: {
    searchQuery() {
      this.currentPage = 1;
      this.fetchDistributors();
    }
  }
};
</script>

<style>
/* 适配移动端的样式 */
@media (max-width: 768px) {
  .ManageDistributors .el-dialog {
    width: 100% !important;
    padding: 0 10px;
    top: 10px;
    left: 0;
  }

  .el-form-item {
    margin-bottom: 10px;
  }

  .export-button, .edit-button, .save-button, .cancel-button {
    width: 100%;
    margin-bottom: 10px;
  }

  .search-input {
    width: 100%;
  }

  .el-dialog__body {
    padding: 20px 10px;
  }

  .distributor-table th, .distributor-table td {
    padding: 10px 5px;
    font-size: 14px;
  }

  .el-dialog__footer {
    padding: 10px;
    display: flex;
    justify-content: space-between;
  }

  .el-dialog {
    top: 0;
  }
}

.pagination {
  margin-top: 20px;
}
</style>