<template>
  <div class="RegisteredUsers">
    <el-container>
      <el-header>
        <h1>已注册用户</h1>
      </el-header>
      <el-main>
        <el-input
          v-model="searchQuery"
          placeholder="搜索用户名"
          @input="fetchUsers"
          clearable
          prefix-icon="el-icon-search"
          style="margin-bottom: 20px; width: 100%;"
        ></el-input>
        <!-- 修复 v-if v-else 语法错误 -->
        <div v-if="users.length > 0">
          <el-table :data="paginatedUsers" style="width: 100%;" border>
            <el-table-column prop="username" label="用户名" :width="isMobile ? 120 : 150"></el-table-column>
            <el-table-column prop="SteamID" label="SteamID" :width="isMobile ? 140 : 180"></el-table-column>
            <el-table-column prop="types" label="用户状态" :width="isMobile ? 100 : 120">
              <template #default="scope">
                <el-tag :type="scope.row.types === '1' ? 'danger' : 'success'">
                  {{ scope.row.types === "1" ? '被封禁' : '正常使用' }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="last_login" label="上次登陆时间" sortable :width="isMobile ? 160 : 200"></el-table-column>
            <el-table-column prop="registered_at" label="注册时间" sortable :width="isMobile ? 160 : 200"></el-table-column>
            <el-table-column prop="register_ip" label="注册IP" :width="isMobile ? 120 : 150"></el-table-column>
            <el-table-column prop="login_ip" label="登录IP" :width="isMobile ? 120 : 150"></el-table-column>
            <el-table-column label="操作" :width="isMobile ? 150 : 200">
              <template #default="scope">
                <el-button                
                  @click="toggleBan(scope.row)"
                  :type="scope.row.types === '1' ? 'danger' : 'primary'"
                  size="small"
                  v-if="owner === '0'"
                >
                  {{ scope.row.types === "1" ? '解封' : '封禁' }}
                </el-button>
                <el-button @click="openEditDialog(scope.row)" type="warning" size="small">
                  编辑
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div v-else>
          <el-empty description="没有用户可显示"></el-empty>
        </div>
        <el-pagination
          background
          layout="prev, pager, next"
          :total="totalPages * pageSize"
          :page-size="pageSize"
          v-model:currentPage="currentPage"
          @current-change="fetchUsers"
          style="margin-top: 20px; text-align: center;"
        ></el-pagination>
      </el-main>
    </el-container>

    <el-dialog
      title="编辑用户"
      v-model="dialogVisible"
      :width="isMobile ? '90%' : '30%'"
      @close="resetForm"
    >
      <el-form :model="currentUser" label-width="100px">
        <el-form-item label="用户名">
          <el-input v-model="currentUser.username" disabled></el-input>
        </el-form-item>
        <el-form-item label="SteamID">
          <el-input v-model="currentUser.SteamID"></el-input>
        </el-form-item>
        <el-form-item label="新密码">
          <el-input type="password" v-model="currentUser.password"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="saveUser">保存</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import axios from '../axios';
import { ElMessage } from 'element-plus';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'RegisteredUsers',
  setup() {
    const store = useStore();
    const isMobile = ref(window.innerWidth <= 768);

    window.addEventListener('resize', () => {
      isMobile.value = window.innerWidth <= 768;
    });

    const owner = computed(() => store.state.user.owner);

    return {
      owner,
      isMobile,
    };
  },
  data() {
    return {
      users: [],
      searchQuery: '',
      currentPage: 1,
      pageSize: 10,
      dialogVisible: false,
      currentUser: {
        username: '',
        SteamID: '',
        password: ''
      }
    };
  },
  computed: {
    filteredUsers() {
      return this.users.filter(user =>
        user.username.includes(this.searchQuery)
      );
    },
    paginatedUsers() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      return this.filteredUsers.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredUsers.length / this.pageSize);
    }
  },
  created() {
    this.fetchUsers();
  },
  methods: {
    async fetchUsers() {
      try {
        const token = localStorage.getItem('user-token');
        const response = await axios.post('/index.php?action=getUsers', { token, search: this.searchQuery });
        this.users = response.data.users || [];
      } catch (error) {
        console.error('Error fetching users:', error);
        this.users = [];
      }
    },
    async toggleBan(user) {
      try {
        const token = localStorage.getItem('user-token');
        const response = await axios.post('/index.php?action=toggleUserBan', { token, username: user.username });
        if (response.data.success) {
          ElMessage.success('状态已更新');
          await this.fetchUsers();
        } else {
          ElMessage.error(response.data.message);
        }
      } catch (error) {
        console.error('Error toggling ban:', error);
        ElMessage.error('操作失败，请稍后重试');
      }
    },
    openEditDialog(user) {
      this.currentUser = { ...user, password: '' };
      this.dialogVisible = true;
    },
    async saveUser() {
      try {
        const token = localStorage.getItem('user-token');
        const { username, SteamID, password } = this.currentUser;
        const response = await axios.post('/index.php?action=updateUser', { token, username, SteamID, password });
        if (response.data.success) {
          ElMessage.success('用户信息已更新');
          this.dialogVisible = false;
          this.fetchUsers();
        } else {
          ElMessage.error(response.data.message);
        }
      } catch (error) {
        console.error('Error updating user:', error);
        ElMessage.error('更新失败，请稍后重试');
      }
    },
    resetForm() {
      this.currentUser = {
        username: '',
        SteamID: '',
        password: ''
      };
    }
  }
};
</script>

<style scoped>
.RegisteredUsers {
  padding: 20px;
  width: 100%;
}

.el-main {
  width: 100%;
}

.el-table {
  width: 100%;
}

.table-container {
  overflow-x: auto; /* 如果表格过宽，则允许横向滚动 */
}

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

/* 移动端自适应 */
@media (max-width: 768px) {
  .RegisteredUsers {
    padding: 10px;
  }

  .el-input {
    width: 100%;
  }

  .table-container {
    overflow-x: scroll;
  }
}
</style>
