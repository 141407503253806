<template>
  <div class="ManagePermissions">
    <el-container>
      <el-header>
        <h1>管理权限组</h1>
      </el-header>
      <el-main>
        <el-row :gutter="20">
          <el-col :span="24" class="permission-table">
            <el-table :data="permissionGroups" style="width: 100%;" :border="true">
              <el-table-column prop="promissID" label="权限组ID" min-width="100"></el-table-column>
              <el-table-column prop="name" label="权限组名称" min-width="150"></el-table-column>
              <el-table-column prop="gameid" label="GAMEID" min-width="200"></el-table-column>
              <el-table-column label="操作" min-width="150">
                <template v-slot="scope">
                  <div class="table-buttons">
                    <el-button @click="openEditDialog(scope.row)" type="primary" size="small">编辑</el-button>
                    <el-button @click="deleteGroup(scope.row.promissID)" type="danger" size="small">删除</el-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-col>

          <el-col :span="24" class="form-container">
            <el-form @submit.native.prevent="addPermissionGroup">
              <el-form-item label="权限组名称">
                <el-input v-model="newGroup.name"></el-input>
              </el-form-item>
              <el-form-item label="搜索游戏">
                <el-input v-model="gameSearch" @input="searchGames"></el-input>
              </el-form-item>
              <el-form-item v-if="searchResults.length">
                <el-select v-model="selectedGameIDs" multiple placeholder="请选择游戏">
                  <el-option
                    v-for="game in searchResults"
                    :key="game.gameid"
                    :label="`${game.gameid} - ${game.title}`"
                    :value="game.gameid">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" native-type="submit">添加</el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </el-main>
    </el-container>

    <!-- Edit Group Dialog -->
    <el-dialog v-model="dialogVisible" title="编辑权限组" width="30%" @close="resetForm">
      <el-form :model="currentGroup" label-width="100px">
        <el-form-item label="权限组名称">
          <el-input v-model="currentGroup.name"></el-input>
        </el-form-item>
        <el-form-item label="游戏">
          <el-select v-model="currentGroup.gameids" multiple placeholder="请选择游戏">
            <el-option
              v-for="game in searchResults"
              :key="game.gameid"
              :label="`${game.gameid} - ${game.title}`"
              :value="game.gameid">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="updatePermissionGroup">保存</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import axios from '../axios';
import { ElMessage } from 'element-plus';
import { ref } from 'vue';

export default {
  name: 'ManagePermissions',
  data() {
    return {
      permissionGroups: [],
      newGroup: {
        name: '',
        gameids: []
      },
      gameSearch: '',
      searchResults: [],
      selectedGameIDs: [],
      dialogVisible: false,
      currentGroup: {
        promissID: '',
        name: '',
        gameids: []
      },
      isMobile: ref(window.innerWidth <= 768)
    };
  },
  methods: {
    async fetchPermissionGroups() {
      try {
        const token = localStorage.getItem('user-token');
        const response = await axios.post('/index.php?action=getPromissGroups', { token });
        if (response.data.code === 200) {
          this.permissionGroups = response.data.groups;
        } else {
          ElMessage.error(response.data.message);
        }
      } catch (error) {
        console.error('Error fetching permission groups:', error);
        ElMessage.error('获取权限组列表失败');
      }
    },
    async addPermissionGroup() {
      try {
        const token = localStorage.getItem('user-token');
        const gameids = this.selectedGameIDs.join(',');
        const response = await axios.post('/index.php?action=addPromissGroup', {
          token,
          name: this.newGroup.name,
          gameids: gameids
        });
        if (response.data.code === 200) {
          this.fetchPermissionGroups();
          this.newGroup.name = '';
          this.selectedGameIDs = [];
          ElMessage.success('权限组添加成功');
        } else {
          ElMessage.error(response.data.message);
        }
      } catch (error) {
        console.error('Error adding permission group:', error);
        ElMessage.error('添加权限组失败');
      }
    },
    async searchGames() {
      try {
        const token = localStorage.getItem('user-token');
        const response = await axios.post('/index.php?action=getGames_make', { token, search: this.gameSearch });
        if (response.data.code === 200) {
          this.searchResults = response.data.games;
        } else {
          ElMessage.error(response.data.message);
        }
      } catch (error) {
        console.error('Error searching games:', error);
        ElMessage.error('搜索游戏失败');
      }
    },
    async deleteGroup(promissID) {
      try {
        const token = localStorage.getItem('user-token');
        const response = await axios.post('/index.php?action=deletePromissGroup', { token, promissID });
        if (response.data.code === 200) {
          this.fetchPermissionGroups();
          ElMessage.success('权限组删除成功');
        } else {
          ElMessage.error(response.data.message);
        }
      } catch (error) {
        console.error('Error deleting permission group:', error);
        ElMessage.error('删除权限组失败');
      }
    },
    openEditDialog(group) {
      this.currentGroup = { ...group };
      this.dialogVisible = true;
    },
    async updatePermissionGroup() {
      try {
        const token = localStorage.getItem('user-token');
        const gameids = this.currentGroup.gameids.join(',');
        const response = await axios.post('/index.php?action=updatePromissGroup', {
          token,
          promissID: this.currentGroup.promissID,
          name: this.currentGroup.name,
          gameids: gameids
        });
        if (response.data.code === 200) {
          this.fetchPermissionGroups();
          this.dialogVisible = false;
          ElMessage.success('权限组更新成功');
        } else {
          ElMessage.error(response.data.message);
        }
      } catch (error) {
        console.error('Error updating permission group:', error);
        ElMessage.error('更新权限组失败');
      }
    },
    resetForm() {
      this.currentGroup = {
        promissID: '',
        name: '',
        gameids: []
      };
    },
    checkMobile() {
      this.isMobile = window.innerWidth <= 768;
    }
  },
  mounted() {
    this.fetchPermissionGroups();
    this.searchGames();  // 初始加载游戏数据
    window.addEventListener('resize', this.checkMobile); // 监听窗口变化
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkMobile); // 清理事件监听
  }
};
</script>

<style scoped>
.ManagePermissions {
  padding: 20px;
}

.permission-table {
  overflow-x: auto; /* 在移动端允许水平滚动 */
}

.table-buttons {
  display: flex;
  justify-content: space-between;
}

.el-table-column {
  word-break: break-all; /* 确保长内容换行 */
}

@media (max-width: 768px) {
  .el-table th, .el-table td {
    font-size: 12px; /* 移动端字体变小 */
  }

  .permission-table {
    font-size: 12px; /* 移动端表格字体大小 */
  }

  .el-form-item {
    font-size: 12px; /* 表单输入框也相应缩小 */
  }
}
</style>
