<template>
  <el-container class="CodeRecords">
    <el-header>
      <h1>兑换码记录</h1>
    </el-header>
    <el-main>
      <el-input
        v-model="searchQuery"
        placeholder="搜索卡密、制卡人或使用用户"
        @input="onSearch"
        clearable
        prefix-icon="el-icon-search"
        style="margin-bottom: 20px;"
      ></el-input>
      <el-table
        :data="paginatedRecords"
        v-if="filteredRecords.length > 0"
        style="width: 100%;"
        :default-sort="{ prop: 'creationTime', order: 'descending' }"
      >
        <el-table-column prop="code" label="兑换码" width="400"></el-table-column>
        <el-table-column prop="category" label="类别" width="100"></el-table-column>
        <el-table-column prop="game" label="所属游戏" width="150"></el-table-column>
        <el-table-column prop="status" label="状态" width="120"></el-table-column>
        <el-table-column prop="creator" label="制卡人" width="120"></el-table-column>
        <el-table-column prop="creationTime" label="制卡时间" sortable width="180"></el-table-column>
        <el-table-column prop="usageTime" label="使用时间" sortable width="180"></el-table-column>
        <el-table-column v-if="owner === '0'" prop="records" label="备注" sortable width="400"></el-table-column>
        <el-table-column prop="user" label="使用用户" width="120"></el-table-column>
        <el-table-column label="操作" width="150">
          <template v-slot="scope">
            <el-button
              v-if="scope.row.user"
              @click="toggleBan(scope.row)"
              type="primary"
              size="small"
            >
              {{ scope.row.status === '被封禁' ? '解封' : '封禁' }}
            </el-button>
            <el-button
              v-else
              @click="deleteRecord(scope.row)"
              type="danger"
              size="small"
            >
              删除
            </el-button>
            <el-button
              v-if="scope.row.category === '豪华版'"
              @click="upgradeVersion(scope.row)"
              type="warning"
              size="small"
            >
              升级
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div v-else>
        <el-empty description="没有记录可显示"></el-empty>
      </div>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="totalPages * pageSize"
        :page-size="pageSize"
        v-model:current-page="currentPage"
        @current-change="onPageChange"
        style="margin-top: 20px; text-align: center;"
      ></el-pagination>
    </el-main>
  </el-container>
</template>

<script>
import axios from '../axios';
import { ElMessage } from 'element-plus';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'CodeRecords',
  data() {
    return {
      records: [],
      searchQuery: '',
      currentPage: 1,
      pageSize: 10,
    };
  },
  setup() {
    const store = useStore();
    const owner = computed(() => store.state.user.owner);
    return { owner };
  },
  computed: {
    filteredRecords() {
      return this.records.filter(record =>
        record.code.includes(this.searchQuery) ||
        record.creator.includes(this.searchQuery) ||
        (record.user && record.user.includes(this.searchQuery))
      );
    },
    paginatedRecords() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      return this.filteredRecords.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredRecords.length / this.pageSize);
    }
  },
  created() {
    this.fetchRecords();
  },
  methods: {
    async fetchRecords() {
      try {
        const token = localStorage.getItem('user-token'); // 假设令牌存储在localStorage中
        const response = await axios.post('/index.php?action=generateRecords', { token, search: this.searchQuery });
        this.records = response.data.records || [];
      } catch (error) {
        console.error('Error fetching records:', error);
        this.records = [];
      }
    },
    async toggleBan(record) {
      try {
        const token = localStorage.getItem('user-token'); // 假设令牌存储在localStorage中
        const response = await axios.post('/index.php?action=toggleBan', { token, keysr: record.code });
        if (response.data.success) {
          record.status = response.data.newStatus;
          ElMessage.success('状态已更新');
        } else {
          ElMessage.error(response.data.message);
        }
      } catch (error) {
        console.error('Error toggling ban:', error);
        ElMessage.error('操作失败，请稍后重试');
      }
    },
    async deleteRecord(record) {
      try {
        const token = localStorage.getItem('user-token'); // 假设令牌存储在localStorage中
        const response = await axios.post('/index.php?action=deleteRecord', { token, keysr: record.code });
        if (response.data.success) {
          this.records = this.records.filter(r => r.code !== record.code);
          ElMessage.success('记录已删除');
        } else {
          ElMessage.error(response.data.message);
        }
      } catch (error) {
        console.error('Error deleting record:', error);
        ElMessage.error('删除失败，请稍后重试');
      }
    },
    async upgradeVersion(record) {
      try {
        const token = localStorage.getItem('user-token'); // 假设令牌存储在localStorage中
        const response = await axios.post('/index.php?action=upgradeVersion', { token, keysr: record.code });
        if (response.data.success) {
          record.category = '终极版';
          ElMessage.success('版本已升级');
        } else {
          ElMessage.error(response.data.message);
        }
      } catch (error) {
        console.error('Error upgrading version:', error);
        ElMessage.error('升级失败，请稍后重试');
      }
    },
    onSearch() {
      this.currentPage = 1; // Reset to first page when search query changes
      this.fetchRecords();
    },
    onPageChange(page) {
      this.currentPage = page;
      this.fetchRecords();
    }
  }
};
</script>

<style scoped>
.CodeRecords {
  padding: 20px;
}

.el-table th, .el-table td {
  white-space: nowrap;
}

.el-table {
  width: 100%;
}
</style>
