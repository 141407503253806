// @ts-ignore
import { createApp } from 'vue';
// @ts-ignore
import App from './App.vue';
// @ts-ignore
import router from './router';
// @ts-ignore
import store from './store';
import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";
import axios from 'axios';

import 'element-plus/dist/index.css'; // 引入 Element Plus 的样式
import ElementPlus from 'element-plus';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import './assets/global.css'; // 引入全局样式

// 创建 Vue 应用实例
// @ts-ignore
const app = createApp(App);

// 注册所有图标
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  // @ts-ignore
  app.component(key, component)
}

// 配置 Toast 插件
const options = {
  position: POSITION.TOP_RIGHT,
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  closeButton: "button",
  icon: true,
  rtl: false
};

// 使用 Toast 插件
// @ts-ignore
app.use(Toast, options);

// 使用 router 和 store
// @ts-ignore
app.use(router);
// @ts-ignore
app.use(store);
app.config.globalProperties.$axios = axios;
// @ts-ignore
app.use(ElementPlus);

// 挂载 Vue 实例
// @ts-ignore
app.mount('#app');
