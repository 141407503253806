<template>
  <div class="GenerateCode">
    <el-container>
      <el-header>
        <h1>生成兑换码</h1>
      </el-header>
      <el-main>
        <el-form @submit.prevent="generateCodes" label-position="top" label-width="100px">
          <el-form-item label="选择游戏">
            <el-select v-model="selectedGame" placeholder="选择游戏" filterable clearable>
              <el-option
                v-for="game in games"
                :key="game.gameid"
                :label="game.title"
                :value="game.gameid"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择版本">
            <el-select v-model="selectedVersion" placeholder="选择版本" clearable>
              <el-option value="1" label="豪华版"></el-option>
              <el-option value="2" label="终极版"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="生成数量">
            <el-input-number v-model="quantity" :min="1" :max="500"></el-input-number>
          </el-form-item>
          <el-form-item label="备注">
            <template v-if="owner === '0'">
              <el-input v-model="records" placeholder="请输入卡密用途及售卖店铺信息"></el-input>
            </template>
            <template v-else>
              <el-select v-model="records" placeholder="选择备注">
                <el-option value="自卖" label="自卖"></el-option>
              </el-select>
            </template>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="generateCodes">生成兑换码</el-button>
          </el-form-item>
        </el-form>
        <p>您当前剩余的制卡次数为：{{ balance }}</p>
        <el-dialog
          title="生成的兑换码"
          v-model="dialogVisible"
          width="50%"
          @close="closeModal"
        >
          <el-table :data="codes" style="width: 100%">
            <el-table-column prop="code" label="兑换码"></el-table-column>
          </el-table>
          <template #footer>
            <el-button @click="copyToClipboard">复制</el-button>
            <el-button @click="exportToTxt">导出为TXT</el-button>
            <el-button type="primary" @click="closeModal">关闭</el-button>
          </template>
        </el-dialog>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import axios from '../axios';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';

export default {
  name: 'GenerateCode',
  setup() {
    const store = useStore();
    const toast = useToast();

    const games = ref([]);
    const selectedGame = ref('');
    const selectedVersion = ref('1');
    const quantity = ref(1);
    const codes = ref([]);
    const dialogVisible = ref(false);
    const records = ref('');
    const owner = computed(() => store.state.user.owner);
    const username = computed(() => store.state.user.username);

    const balance = computed(() => store.state.user.balance);

    const fetchGames = async () => {
      try {
        const token = localStorage.getItem('user-token');
        const userInfoResponse = await axios.post('/index.php?action=getUserInfo', { token });
        const promissID = userInfoResponse.data.user.promiss;

        let response;
        if (!promissID || promissID === 0) {
          response = await axios.post('/index.php?action=getGames_make', { token });
        } else {
          response = await axios.post('/index.php?action=getGames_make', { token, promissID });
        }

        games.value = response.data.games;
      } catch (error) {
        console.error('Error fetching games:', error);
      }
    };

    const generateCodes = async () => {
      try {
        const token = localStorage.getItem('user-token');
        const response = await axios.post('/index.php?action=generateCodes', {
          token,
          gameid: selectedGame.value,
          codin: selectedVersion.value,
          quantity: quantity.value,
          records: records.value
        });

        if (response.data.code === 200) {
          codes.value = response.data.codes.map(code => ({ code }));
          dialogVisible.value = true;

          // 更新 Vuex 中的 createNum 和 balance
          store.commit('updateCreateNum', store.state.user.createNum + quantity.value);
          store.commit('updateBalance', store.state.user.balance - quantity.value);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        console.error('Error generating codes:', error);
        toast.error('生成兑换码时发生错误，请稍后重试');
      }
    };

    const copyToClipboard = () => {
      const text = codes.value.map(c => c.code).join('\n');
      navigator.clipboard.writeText(text).then(() => {
        toast.success('已复制到剪贴板');
      }, (err) => {
        console.error('复制失败', err);
        toast.error('复制失败');
      });
    };

    const exportToTxt = () => {
      const text = codes.value.map(c => c.code).join('\n');
      const blob = new Blob([text], { type: 'text/plain;charset=utf-8' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'codes.txt';
      link.click();
    };

    const closeModal = () => {
      dialogVisible.value = false;
    };

    fetchGames();

    return {
      games,
      owner,
      username,
      selectedGame,
      selectedVersion,
      quantity,
      codes,
      balance,
      dialogVisible,
      records,
      generateCodes,
      copyToClipboard,
      exportToTxt,
      closeModal
    };
  }
};
</script>

<style scoped>
.GenerateCode {
  padding: 20px;
}

.el-dialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.el-dialog__footer {
  display: flex;
  justify-content: flex-end;
}
</style>
