import axios from 'axios';
import router from './router';
import store from './store';

// 创建 axios 实例
const instance = axios.create({
  baseURL: 'https://api.gameplanet.vip/admin', // 替换为你的API基础路径
  timeout: 10000, // 请求超时时间
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  }
});

instance.interceptors.request.use(
  config => {
    const token = localStorage.getItem('user-token');
    if (token && !config.url.includes('action=login') && !config.url.includes('action=register')) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    // 序列化数据
    config.data = new URLSearchParams(config.data).toString();
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  response => {
    // 在每次成功响应后更新token
    if (response.data.token) {
      localStorage.setItem('user-token', response.data.token);
    }
    return response;
  },
  error => {
    if (error.response) {
      if (error.response.status === 401 || error.response.data.code === 'TOKEN_EXPIRED') {
        localStorage.removeItem('user-token');
        store.dispatch('logout'); // 触发登出逻辑
        router.push('/login');
      }
    }
    return Promise.reject(error);
  }
);

export default instance;
