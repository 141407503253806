<template>
  <div class="AddDistributor">
    <el-container>
      <el-header>
        <h1>添加经销商</h1>
      </el-header>
      <el-main>
        <el-form :model="form" :rules="rules" ref="form" label-width="120px">
          <el-form-item label="用户名" prop="username">
            <el-input v-model="form.username"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input type="password" v-model="form.password"></el-input>
          </el-form-item>
          <el-form-item label="所属上级" prop="owner">
            <el-input v-model="form.owner"></el-input>
          </el-form-item>
          <el-form-item label="可用制卡次数" prop="balance">
            <el-input v-model="form.balance"></el-input>
          </el-form-item>
          <el-form-item label="可用赠卡次数" prop="balanceZS">
            <el-input v-model="form.balanceZS"></el-input>
          </el-form-item>
          <el-form-item label="权限" prop="promiss">
            <el-select v-model="form.promiss" placeholder="请选择权限">
              <el-option label="普通经销" value="0"></el-option>
              <el-option label="可封号" value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注项" prop="bindQQ">
            <el-input v-model="form.bindQQ"></el-input>
          </el-form-item>
          <el-form-item label="绑定手机" prop="bindPhone">
            <el-input v-model="form.bindPhone"></el-input>
          </el-form-item>
          <el-form-item label="关联淘宝店" prop="TaobaoShop">
            <el-input v-model="form.TaobaoShop"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">提交</el-button>
            <el-button @click="onReset">重置</el-button>
          </el-form-item>
        </el-form>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import axios from '../axios';
import { ElMessage } from 'element-plus';

export default {
  name: 'AddDistributor',
  data() {
    return {
      form: {
        username: '',
        password: '',
        owner: '',
        balance: '',
        balanceZS: '',
        promiss: '',
        bindQQ: '',
        bindPhone: '',
        TaobaoShop: ''
      },
      rules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          { min: 3, max: 30, message: '长度在 3 到 30 个字符', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, message: '密码至少6位', trigger: 'blur' }
        ],
        owner: [
          { required: true, message: '请输入所属上级', trigger: 'blur' }
        ],
        balance: [
          { required: true, message: '请输入可用制卡次数', trigger: 'blur' }
        ],
        promiss: [
          { required: true, message: '请选择权限', trigger: 'change' }
        ]
      }
    };
  },
  methods: {
    async onSubmit() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          try {
            const token = localStorage.getItem('user-token');
            const response = await axios.post('/index.php?action=addDistributor', {
              token,
              username: this.form.username,
              password: this.form.password,
              owner: this.form.owner,
              balance: this.form.balance,
              balanceZS: this.form.balance,
              promiss: this.form.promiss,
              bindQQ: this.form.bindQQ || '',
              bindPhone: this.form.bindPhone || '',
              TaobaoShop: this.form.TaobaoShop || '',
              createNum: '0'
            });

            if (response.data.success) {
              ElMessage.success('经销商注册成功');
              this.onReset();
            } else {
              ElMessage.error(response.data.message);
            }
          } catch (error) {
            console.error('Error adding distributor:', error);
            ElMessage.error('注册失败，请稍后重试');
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    onReset() {
      this.$refs.form.resetFields();
    }
  }
};
</script>

<style scoped>
.AddDistributor {
  padding: 20px;
}
</style>
