<template>
  <el-container>
    <el-aside :class="{ 'mobile-full': isMobile }" width="40%">
      <h2>已发布的公告</h2>
      <el-table :data="announcements" style="width: 100%" height="400">
        <el-table-column prop="username" label="发布人" width="120"></el-table-column>
        <el-table-column prop="pushtime" label="发布时间" width="180"></el-table-column>
        <el-table-column prop="info" label="内容"></el-table-column>
        <el-table-column prop="noticeid" label="公告ID" width="180"></el-table-column>
        <el-table-column label="操作" width="150">
          <template #default="scope">
            <el-button @click="editAnnouncement(scope.row)" type="primary" size="small">修改</el-button>
            <el-button @click="deleteAnnouncement(scope.row.noticeid)" type="danger" size="small">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-aside>

    <el-main :class="{ 'mobile-full': isMobile }">
      <h2>发布公告</h2>
      <el-form :model="form" label-width="100px">
        <el-form-item label="发布人">
          <el-input v-model="form.username"></el-input>
        </el-form-item>
        <el-form-item label="内容">
          <el-input type="textarea" v-model="form.info"></el-input>
        </el-form-item>
        <el-form-item label="权限">
          <el-select v-model="form.permission" placeholder="请选择权限">
            <el-option label="管理员和用户可见" value="1"></el-option>
            <el-option label="只有管理员可见" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm">发布</el-button>
          <el-button @click="resetForm">重置</el-button>
        </el-form-item>
      </el-form>
    </el-main>
  </el-container>
</template>

<script>
import axios from '../axios';
import { ElMessage } from 'element-plus';
import { ref } from 'vue';

export default {
  name: 'ManageAnnouncements',
  data() {
    return {
      announcements: [],
      form: {
        username: '',
        info: '',
        permission: '1', // 默认权限为管理员和用户可见
        noticeid: ''
      },
      isMobile: ref(window.innerWidth <= 768) // 判断是否为移动端
    };
  },
  created() {
    this.fetchAnnouncements();
    window.addEventListener('resize', this.checkMobile); // 监听窗口变化
  },
  methods: {
    checkMobile() {
      this.isMobile = window.innerWidth <= 768;
    },
    fetchAnnouncements() {
      const token = localStorage.getItem('user-token');
      axios.post('/index.php?action=getAnnouncements', { token })
        .then(response => {
          if (response.data.success) {
            this.announcements = response.data.announcements;
          } else {
            ElMessage.error(response.data.message);
          }
        })
        .catch(error => {
          console.error('Error fetching announcements:', error);
        });
    },
    editAnnouncement(announcement) {
      this.form = { ...announcement };
    },
    deleteAnnouncement(noticeid) {
      const token = localStorage.getItem('user-token');
      axios.post('/index.php?action=deleteAnnouncement', { token, noticeid })
        .then(response => {
          if (response.data.success) {
            ElMessage.success('公告删除成功');
            this.fetchAnnouncements();
          } else {
            ElMessage.error(response.data.message);
          }
        })
        .catch(error => {
          console.error('Error deleting announcement:', error);
          ElMessage.error('删除失败，请稍后重试');
        });
    },
    submitForm() {
      const token = localStorage.getItem('user-token');
      const url = this.form.noticeid ? '/index.php?action=updateAnnouncement' : '/index.php?action=addAnnouncement';
      axios.post(url, { ...this.form, token })
        .then(response => {
          if (response.data.success) {
            ElMessage.success(this.form.noticeid ? '公告更新成功' : '公告发布成功');
            this.resetForm();
            this.fetchAnnouncements();
          } else {
            ElMessage.error(response.data.message);
          }
        })
        .catch(error => {
          console.error('Error submitting form:', error);
          ElMessage.error('提交失败，请稍后重试');
        });
    },
    resetForm() {
      this.form = {
        username: '',
        info: '',
        permission: '1',
        noticeid: ''
      };
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkMobile); // 清理监听器
  }
};
</script>

<style scoped>
.el-container {
  padding: 20px;
  display: flex;
  flex-direction: row;
}

.mobile-full {
  width: 100%;
  margin-bottom: 20px;
}

h2 {
  margin-bottom: 20px;
}

.el-aside {
  padding-right: 20px;
}

.el-main {
  padding-left: 20px;
}

.el-table {
  margin-bottom: 20px;
}

/* 在小于 768px 的设备上进行调整 */
@media (max-width: 768px) {
  .el-container {
    flex-direction: column;
  }

  .el-aside {
    padding-right: 0;
  }

  .el-main {
    padding-left: 0;
  }
}
</style>
