import { createStore } from 'vuex';

export default createStore({
  state: {
    user: {
      username: '',
      types: '',
      owner: '',
      balance: 0,
      balanceZS: 0,
      createNum: 0,
      createNumZS: 0
    }
  },
  mutations: {
    login(state, user) {
      state.user.username = user.username;
      state.user.types = user.types;
      state.user.owner = user.owner;
      state.user.balance = user.balance;
      state.user.createNum = user.createNum;
      state.user.balanceZS = user.balanceZS;
      state.user.createNumZS = user.createNumZS;
    },
    logout(state) {
      state.user.username = '';
      state.user.types = '';
      state.user.owner = '';
      state.user.balance = 0;
      state.user.createNum = 0;
      state.user.balanceZS = 0;
      state.user.createNumZS = 0;
    },
    updateCreateNum(state, newCreateNum) {
      state.user.createNum = newCreateNum;
    },
    updateBalance(state, newBalance) {
      state.user.balance = newBalance;
    },
    updateCreateNumZS(state, newCreateNumZS) {
      state.user.createNumZS = newCreateNumZS;
    },
    updateBalanceZS(state, newBalanceZS) {
      state.user.balanceZS = newBalanceZS;
    }
  },
  actions: {
    login({ commit }, user) {
      commit('login', user);
    },
    logout({ commit }) {
      commit('logout');
      localStorage.removeItem('user-token');
    }
  },
  modules: {}
});
